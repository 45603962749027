let mainPath = "/registers";
let viewFolder = "Register";

module.exports = [
    {
        path: mainPath,
        name: 'registers',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/Open.vue'),
        // beforeEnter: (to, from, next) => {
        //   if (!localStorage.getItem('userId')) next({ name: 'Home' })
        //   else next()
        // }
    },
    {
        path: mainPath+'/view/:id',
        name: 'view',
        component: () => import('../views/'+viewFolder+'/View.vue'),
    },
    {
        path: mainPath+'/close/:id',
        name: 'close',
        component: () => import('../views/'+viewFolder+'/Close.vue'),
    },
]