let mainPath = "/receivingreports";
let mainPath2 = "/receiving";
let viewFolder = "ReceivingReport";

module.exports = [
    {
        path: mainPath2,
        name: 'receivingReports',
        component: () => import('../views/'+viewFolder+'/Index.vue'),
    },
    {
        path: mainPath2+'/view/:id',
        name: 'receivingReport',
        component: () => import('../views/'+viewFolder+'/View.vue'),
    },
    {
        path: mainPath+'/view/:id',
        name: 'receivingReportOld',
        component: () => import('../views/'+viewFolder+'/ViewOld.vue'),
    }
]