let mainPath = "/purchaseorders";
let viewFolder = "PurchaseOrders";

module.exports = [
    {
        path: "/purchasing",
        name: 'purchaseOrders',
        component: () => import('../views/'+viewFolder+'/Index.vue'),
    },
    {
        path: mainPath+'/view/:id',
        name: 'purchaseOrder',
        component: () => import('../views/'+viewFolder+'/View.vue'),
    },
    {
        path: mainPath+'/create/:id',
        name: 'Edit Purchase Order',
        component: () => import('../views/'+viewFolder+'/Create.vue'),
    }
]