let mainPath = "/products";
let viewFolder = "Product";

module.exports = [
    {
        path: mainPath,
        name: 'products',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/Index.vue'),
        // beforeEnter: (to, from, next) => {
        //   if (!localStorage.getItem('userId')) next({ name: 'Home' })
        //   else next()
        // }
    },
    {
        path: mainPath+'/view/:id',
        name: 'product',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/View.vue'),
        // beforeEnter: (to, from, next) => {
        //   if (!localStorage.getItem('userId')) next({ name: 'Home' })
        //   else next()
        // }
    },
    {
        path: mainPath+'/create',
        name: 'Create Product',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/Create.vue'),
        // beforeEnter: (to, from, next) => {
        //   if (!localStorage.getItem('userId')) next({ name: 'Home' })
        //   else next()
        // }
    },
    {
        path: mainPath+'/serial/:id',
        name: 'View Product Serial',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/ProductSerial.vue'),
    },
]