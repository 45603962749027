let viewFolder = "ProductAttributeTerm";

module.exports = [
    {
        path: '/productattributes/view/:productAttributeId/terms/view/:id',
        name: 'productattributeterms',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/View.vue'),
        // beforeEnter: (to, from, next) => {
        //   if (!localStorage.getItem('userId')) next({ name: 'Home' })
        //   else next()
        // }
    }
]