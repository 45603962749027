let mainPath = "/inventoryTransfers";
let viewFolder = "InventoryTransfer";

module.exports = [
    {
        path: mainPath,
        name: 'inventoryTransfers',
        component: () => import('../views/'+viewFolder+'/Index.vue'),
    },
    {
        path: mainPath+'/view/:id',
        name: 'inventoryTransfer',
        component: () => import('../views/'+viewFolder+'/View.vue'),
    },
]