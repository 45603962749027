let mainPath = "/logs";
let viewFolder = "Log";

module.exports = [
    {
        path: mainPath,
        name: 'logs',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/Index.vue'),
        // beforeEnter: (to, from, next) => {
        //   if (!localStorage.getItem('userId')) next({ name: 'Home' })
        //   else next()
        // }
    },
    {
        path: mainPath+'/producthistory*/:id',
        name: 'producthistory',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/ProductHistory.vue'),
        // beforeEnter: (to, from, next) => {
        //   if (!localStorage.getItem('userId')) next({ name: 'Home' })
        //   else next()
        // }
    }
]