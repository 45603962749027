<template>
  <v-dialog v-model="dialog" :width="width">
    <v-card :color="color || 'primary'">
      <v-card-title v-if="title" :class="computedTextClass">{{ title }}</v-card-title>
      <v-card-text :class="computedTextClass+` ${title?'':'pt-5'}`">
        {{ text }}
      </v-card-text>
      <v-card-actions>
        <v-btn text :class="computedTextClass" @click="closeDialog">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  props: {
    width: {
      type: Number,
      default: 600
    },
    color: {
      type: String,
      default: "primary",
    },
    text: {
      type: [String, Error, Object]
    },
    textClass: String,
    title: String,
    timeout: {
      type: Number,
      default: 3000,
    },
  },
  data() {
    return {
      dialog: false,
      closeTimeoutId: null,
    };
  },
  mounted(){

  },
  computed: {
    ...mapGetters(['getGlobalValue']),
    computedWidth(){
      return parseInt(this.width||this.getGlobalValue('dialogSnackWidth')||600);
    },
    computedTimeout(){
      return parseInt(this.timeout||this.getGlobalValue('dialogSnackTimeout')||3000);
    },
    computedTextClass(){
      return (this.textClass||this.getGlobalValue('dialogSnackTextClass')||'white--text')
    }
  },
  methods: {
    openDialog() {
      this.dialog = true;
      if (this.timeout) {
        if (this.closeTimeoutId) {
          clearTimeout(this.closeTimeoutId); // Clear previous timeout
        }
        this.closeTimeoutId = setTimeout(this.closeDialog, this.timeout);
      }
    },
    closeDialog() {
      this.dialog = false;
      if (this.closeTimeoutId) {
        clearTimeout(this.closeTimeoutId); // Clear the timeout when the close button is clicked
      }
    },
  },
};
</script>
