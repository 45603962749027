let mainPath = "/returns";
let viewFolder = "Return";

module.exports = [
    {
        path: mainPath,
        name: 'Returns',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/Index.vue'),
        // beforeEnter: (to, from, next) => {
        //   if (!localStorage.getItem('userId')) next({ name: 'Home' })
        //   else next()
        // }
    },
    {
        path: mainPath+'/view/:id',
        name: 'Return',
        component: () => import('../views/'+viewFolder+'/View.vue'),
    }
]