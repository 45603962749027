let mainPath = "/productcategories";
let viewFolder = "ProductCategory";

module.exports = [
    {
        path: mainPath,
        name: 'productcategories',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/Index.vue'),
        // beforeEnter: (to, from, next) => {
        //   if (!localStorage.getItem('userId')) next({ name: 'Home' })
        //   else next()
        // }
    },
    {
        path: mainPath+'/view/:id',
        name: 'productcategory',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/View.vue'),
        // beforeEnter: (to, from, next) => {
        //   if (!localStorage.getItem('userId')) next({ name: 'Home' })
        //   else next()
        // }
    }
]