let mainPath = "/reporting";
let viewFolder = "Reporting";

module.exports = [
    {
        path: mainPath,
        name: 'reports',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/Index.vue'),
    },
    {
        path: mainPath+'/paymentHistory',
        name: 'Payment History',
        permission: ["reports", "paymentHistory"],
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/PaymentHistory.vue'),
    },
    {
        path: mainPath+'/paymentHistoryWithOrder',
        name: 'Payment History With Orders',
        permission: ["reports", "paymentHistoryWithOrder"],
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/PaymentHistoryWithOrder.vue'),
    },
    {
        path: mainPath+'/vatsales',
        name: 'VAT Sales',
        permission: ["reports", "vatSales"],
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/VatSales.vue'),
    },
    {
        path: mainPath+'/registerreport',
        name: 'Register Report',
        permission: ["reports", "registerReport"],
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/RegisterReport.vue'),
    },
    {
        path: mainPath+'/quarterlySales',
        name: 'Quarterly Sales',
        permission: ["reports", "quarterlySales"],
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/QuarterlySales.vue'),
    },
    {
        path: mainPath+'/:customReport',
        name: 'CustomReport',
        component: () => import('../views/'+viewFolder+'/CustomReport.vue')
    }
]