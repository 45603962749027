let frontendAuth = true

import store from "../store";

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import SuperAdmin from './superAdmin'
import Customers from "./customers";
import Locations from "./locations";
import UserTypes from "./userTypes";
import Users from "./users";
import PurchaseOrders from "./purchaseOrders";
import Suppiers from "./suppliers";
import Orders from "./orders";
import OrderTypes from "./orderTypes";
import PaymentTypes from "./paymentTypes";
import ProductAttributes from "./productAttributes";
import ProductAttributeTerms from "./productAttributeTerms";
import ProductTags from "./productTags";
import ProductCategories from "./productCategories";
import Products from "./products";
import Brand from "./brands";
import Device from "./devices";
import ReceivingReport from "./receivingReports";
import Delivery from "./deliveries";
import Returns from "./returns";
import Reporting from "./reporting";
import Logs from "./logs";
import Promotions from "./promotions";
import InventoryTransfers from "./inventoryTransfers";
import Dispatch from "./dispatch";
import Register from "./registers";
import StocktakingEvent from "./stocktakingEvents";
import HirePurchase from "./hirePurchase";

import auth from "../middleware/routerAuth";

Vue.use(VueRouter)

let routes = []
let defaultRoutes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/requestPasswordReset',
    name: 'RequestPasswordReset',
    component: () => import(/* webpackChunkName: "Login" */ '../views/RequestPasswordReset.vue'),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem('id')) next()
      else next({name: from.name})
    }
  }, 
  {
    path: '/reset',
    name: 'Reset',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Reset.vue'),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem('id')) next()
      else next({name: from.name})
    }
  },  
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue'),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem('id') && to.path !== '/requestPasswordReset' && to.path !== '/reset') next()
      else next({name: from.name})
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "Logout" */ '../views/Logout.vue'),
    // beforeEnter: (to, from, next) => {
    //   if (!localStorage.getItem('id')) next()
    //   else next({name: from.name})
    // }
  }
]

routes = routes.concat(
    defaultRoutes,
    SuperAdmin,
    Customers,
    Locations,
    UserTypes,
    Users,
    Suppiers,
    OrderTypes,
    PaymentTypes,
    ProductAttributes,
    ProductAttributeTerms,
    ProductTags,
    ProductCategories,
    Products,
    PurchaseOrders,
    Brand,
    Device,
    Orders,
    ReceivingReport,
    Delivery,
    Returns,
    Reporting,
    Logs,
    Promotions,
    InventoryTransfers,
    Dispatch,
    Register,
    StocktakingEvent,
    HirePurchase
);

routes = routes.map(x => {
  return {
    ...x,
    meta: {
      middleware: auth
    }
  };
});

const router = new VueRouter({
  routes
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  let found = routes.find(x => x.name===to.name);

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)? to.meta.middleware:[to.meta.middleware];
    const context = {from, next, router, to, store, permission: found?.permission};
    const nextMiddleware = nextFactory(context, middleware, 1);
    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

if(frontendAuth){
  router.beforeEach((to, from, next) => {
    if (!localStorage.getItem('id') && to.path !== '/login' && to.path !== '/requestPasswordReset' && to.path !== '/reset' ) next({ name: 'Login' })
    else next()
  })
}


export default router
