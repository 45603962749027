<template>
  <span>
    <v-btn ref="mainFocus" v-if="!icon" :metadata="metadata" :text="text" :loading="loading" :requireUsername="requireUsername" :requirePassword="requirePassword" :requireReason="requireReason" :disabled="disabled" :fab="fab" :small="small" :x-small="xsmall" :large="large" :x-large="xlarge" :color="color || 'success'" @click="openDialog()">
      {{ buttonText }}
      <v-icon v-if="fab">{{ fabIcon }}</v-icon>
    </v-btn>
    <v-icon v-else :x-small="xsmall" :small="small" :large="large" :x-large="xlarge" :color="color || 'success'" @click="openDialog()">
      {{ fabIcon }}
    </v-icon>
    <v-dialog
      style="z-index: 9999;"
        v-model="dialog.isOpen"
        persistent
        width="500px"
    >
      <v-card>
        <v-card-title class="text-h5 d-flex flex-column align-center justify-center">
          <b>Confirm this Operation.</b>
        </v-card-title>
        <v-card-text>
          <h3>{{dialogText}}</h3>
          <div v-if="requireUsername" class="mt-6">
            <v-text-field type="text" dense label="Username" outlined v-model="username"/>
          </div>
          <div v-if="requirePassword" class="mt-6">
            <v-text-field :type="showPassword?'text':'password'" dense label="Password" outlined v-model="password" :append-icon="showPassword?'mdi-eye':'mdi-eye-off'" @click:append="showPassword = !showPassword"/>
          </div>
          <div v-if="requireReason" class="mt-3">
            <v-textarea class="mt-4" dense name="Reason" label="Reason" v-model="reason" outlined ></v-textarea>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn text  @click="closeDialog()">
            Cancel
          </v-btn>
          <v-btn :disabled="(requireUsername && !username) || (requirePassword && !password) || (requireReason && !reason)" color="info" @click="confirm()">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
// import {mapGetters, mapMutations} from 'vuex'
export default {
  name: 'confirmedActionButton',
  props: {
    text: Boolean,
    icon: Boolean,
    fabIcon: String,
    buttonText: String,
    dialogText: String,
    color: String,
    fab: Boolean,
    small: Boolean,
    xsmall: Boolean,
    large: Boolean,
    xlarge: Boolean,
    disabled: Boolean,
    requireUsername: Boolean,
    requirePassword: Boolean,
    requireReason: Boolean,
    loading: Boolean,
    metadata: Object,
  },
  data: () => ({
    dialog: {
      isOpen: false,
      awaitingScanResult: false,
      text: '',
    },
    username: null,
    password: null,
    reason: null,
    showPassword: false,
  }),
  mounted(){
  },
  // computed: {
  //   ...mapGetters([]),
  // },
  methods: {
    // ...mapMutations([]),
    async openDialog(){
      try {
        this.dialog.isOpen = true
        this.dialog.text = this.dialogText
        this.$refs.typeBox[0].focus()
        console.log(this.$refs.typeBox)
        // this.$nextTick(()=> {
        //   this.$refs.mainFocus[0].focus()
        // })
      } catch (error) {
        console.log(error)
      }
    },
    closeDialog(){
      this.dialog = {
        isOpen: false,
        text: '',
      }
      this.username = null
      this.password = null
      this.reason = null
      this.showPassword = false
    },
    confirm(){
      let obj = {
        username: this.username,
        password: this.password, 
        reason: this.reason,
        metadata: this.metadata
      }
      this.$emit('cb', obj)
      this.closeDialog()
    },
  }
}
</script>
