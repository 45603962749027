export default function auth({ next, store, router, permission }) {
    if(!permission) return next();

    if(permission.length!==2){
        console.error("Invalid Permission for Page.")
        return router.push({name: "Home"});
    }
    if(!store.getters.isAllowed(permission[0], permission[1])) {
        console.error("Permission Denied.")
        return router.push({ name: 'Home' });
    }

    return next();
}